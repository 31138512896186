import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {Col, Row} from 'reactstrap'
import { Link } from 'gatsby' 

class MovieJumbotronBox extends React.Component {
  state = {
    posterHeight: 268,
    posterWidth: 182,
    videoHeight: 9,
    videoWidth: 16,
  }

  onImageLoad = (event) => {
    this.setState({
      posterHeight: event.target.height,
      posterWidth: event.target.width,
    })
  }

  componentDidUpdate(){

  }

  render(){
    return (
      <RatioContainer className="p-0 pb-3 p-md-3">
        <PosterBox className="d-none d-md-flex" height={this.state.posterHeight} width={this.state.posterWidth} neighboorHeight={this.state.videoHeight} neighboorWidth={this.state.videoWidth}>
          <Item>
            <img onLoad={this.onImageLoad} title={this.props.title && this.props.title} className="w-100" src={this.props.poster && this.props.poster.url} alt={this.props.title && this.props.title}/> 
          </Item>
        </PosterBox>
        { this.props.video ?
          <VideoBox height={this.state.videoHeight} width={this.state.videoWidth} neighboorHeight={this.state.posterHeight} neighboorWidth={this.state.posterWidth}>
            <Item>
              {this.props.video} 
            </Item>
          </VideoBox>
          // <VideoBox height={this.state.videoHeight} width={this.state.videoWidth} neighboorHeight={this.state.posterHeight} neighboorWidth={this.state.posterWidth}>
            // <Item height={this.state.posterHeight}>
            //   {this.props.fallback}
            // </Item>
          // </VideoBox> 
          :
          <VideoBox height={this.state.videoHeight} width={this.state.videoWidth} neighboorHeight={this.state.posterHeight} neighboorWidth={this.state.posterWidth}>
            <Item>
              {this.props.video} 
            </Item>
          </VideoBox>
          // <ItemStandalone>
          //   {this.props.video} 
          // </ItemStandalone>
        }
      </RatioContainer>
    )
  }
}

const RatioContainer = styled.div`
  max-width: 1200px !important;
  margin: 0 auto !important;
  // padding: 20px !important;
  display: flex !important;
  align-items: flex-start !important;
`
const PosterBox = styled.div`
  position: relative !important;
	width: ${props => props.neighboorHeight && props.neighboorWidth ? `calc(${props.neighboorHeight} / ${props.neighboorWidth} * 100%)` : '56.25%'};
  background-color: #FFF !important;
  margin-right: 10px !important;
  overflow: hidden !important;
  &:before {
    content: "" !important;
    display: block !important;
    padding-top: ${props => props.height && props.width ? `calc(${props.height} / ${props.width} * 100%)` : '147.2527472527%'} !important;
  }
`
const VideoBox = styled.div`
  position: relative !important;
  width: ${props => props.neighboorHeight && props.neighboorWidth ? `calc(${props.neighboorHeight} / ${props.neighboorWidth} * 100%)` : '147.2527472527%'} !important;
  background-color: #FFF !important;
  // height: ${props => props.height && props.height}px

  &:before{
    content: "" !important;
    display: block !important;
    padding-top: ${props => props.height && props.width ? `calc(${props.height} / ${props.width} * 100%)` : '56.25%'}; !important 
  }
`
const Item = styled.div`
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
`
const ItemStandalone = styled.div`
  // position: absolute !important;
  // top: 0 !important;
  width: 100% !important;
`


export default MovieJumbotronBox