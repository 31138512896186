import React from "react"
import StarRatingComponent from 'react-star-rating-component'
import Comment, { CommentImproved } from './Comment'
import StarRatingPopover from './StarRatingPopover'
import {rateItem} from '../api'
import {Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {toast} from 'react-toastify'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { Trans } from "react-i18next"

export default ({ratings, objectId, type, user, token, currUserRating}) => {
  let allRatings = ratings.filter(i => i.headline !== null && i.content !== null )
  if (ratings){
    allRatings = allRatings.map(i => {
      console.log(i.headline)
      if (i.headline && i.content) {
        return <Comment key={i.alternative_id} title={i.headline} author={i.username} content={i.content} rating={i.rating}/>
      }
      return null
    })
  }
  const ratingNb = `${allRatings.length} commentaire${allRatings.length !== 1 ? 's' : ''}`
  return (
  <div className="w-100">
    <div className="col-lg-8 col-xs-12 py-lg-3 px-lg-3 px-lg-1 mb-5 mx-md-3" style={{marginTop: 100}}>
      <div className="text-left col-sm-9">
        <h3 className="text-left font-weight-bold my-0 mr-2 mx-sm-0"> {ratingNb} </h3>
        { !user 
          ? <StarRatingPopover><button className="btn btn-link btn-sm pl-0" >Écrire un commentaire</button></StarRatingPopover>
          : <ModalRating objectId={objectId} type={type} currUserRating={currUserRating} userpk={user.pk} token={token}/>
        }
      </div>
      <div className="mb-3"></div>
      <div className="d-flex flex-column col-12">
        <AllRatingsList ratings={ratings} />
      </div>
    </div>
  </div>
  )
}

export const RatingButton = ({objectId, type, user, token, currUserRating, handleRatingUpdateCallback}) => (
  <div className="w-100">
    { !user 
      ? <StarRatingPopover><button className="btn btn-link btn-sm pl-0" >Laisser une note</button></StarRatingPopover>
      : <ModalRating objectId={objectId} type={type} currUserRating={currUserRating} userpk={user.pk} token={token} handleRatingUpdateCallback={handleRatingUpdateCallback}/>
    }
  </div>
)

export const AllRatingsList = ({ratings, customCommentClassName, currUserRating, user}) => {
  let allRatings = ratings.filter(i => i.headline !== null && i.content !== null )
  const username = user && user.username 
  ? user.username
  : null
  if (ratings){
    allRatings = allRatings.map(i => {
      if (i.headline && i.content && i.username !== username) {
        return <CommentImproved key={i.alternative_id} title={i.headline} author={i.username} content={i.content} rating={i.rating} date={i.pub_date} customCommentClassName={customCommentClassName}/>
      }
      return null
    })
    if (currUserRating && currUserRating.headline && currUserRating.content) {
      allRatings.unshift(
        <CommentImproved key={currUserRating.id} title={currUserRating.headline} author={currUserRating.username} content={currUserRating.content} rating={currUserRating.rating} customCommentClassName={customCommentClassName}/>
      )
    }
  }
  return (
    <div>
      {allRatings}
    </div>
  )
}

class ModalRatingForm extends React.Component {
  render(){
    return (
      <div className="m-3">

        <form onSubmit={this.props.handleFormSubmit}>
          <div className="col-12m-auto">
    
          <div className="form-row ">
              <div className="col">
                <label htmlFor="rating"> <Trans>Note</Trans><i className="font-weight-lighter">({this.props.rating}/5)</i> </label> 
                <br />
                  <StarRatingComponent 
                    className="h3"
                    name="rating" 
                    editing={true}
                    starCount={5}
                    value={this.props.rating}
                    onStarClick={this.props.handleStarClick}
                    onStarHover={this.props.handleStarHover}
                  /> 
              </div>
          </div>

            
            <div className="form-row mb-3">
              <div className="col">
                <label htmlFor="headline"> <Trans>Titre</Trans> </label>
                <input name="headline" type="text" id="headline" className="form-control" onChange={this.props.handleInputChange} value={this.props.headline}/>
              </div>
            </div>
        
            <div className="form-group">
              <label htmlFor="content"><Trans>Commentaire</Trans></label>
              <textarea className="form-control" id="content" rows="4" name="content"onChange={this.props.handleInputChange} value={this.props.content}></textarea>
            </div>
          
      
          </div>		
        </form>
      </div>
    )
  }
}

export class ModalRating extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rating: null,
      headline: '',
      content: '',
      modal: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleStarClick = this.handleStarClick.bind(this)
    this.handleStarHover = this.handleStarHover.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const rating = this.props.currUserRating
      if (rating){
        this.setState({
          rating: rating.rating,
          headline: rating.headline || '',
          content: rating.content || '',
        })
      }
    }
  }

  componentDidMount(){

  }

  handleInputChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({
			[name]: value,
		})
  }

  async handleFormSubmit(event) {
    event.preventDefault()

    trackCustomEvent({
      category: "Rating",
      action: "Rating Attempt",
      label: `Attempt to send this rating: ${this.state.rating}----${this.state.headline}----${this.state.content}`,
    })
    // Check for errors
    if (!this.state.rating) {
      toast.warn('Note manquante')
      return
    } else if (this.state.headline.length < 4) {
      !this.state.headline.length ? toast.warn('Titre manquant') : toast.warn('Le titre est trop court')
      return
    } else if (!this.state.content || this.state.content.length < 10) {
      !this.state.content.length ? toast.warn('Commentaire manquant') : toast.warn('Le commentaire est trop court')
      return
    }

    const {userpk, token} = this.props
    let method
    let rating = {
      content_type: this.props.type,
      user: userpk,
      object_id: this.props.objectId,
      rating: this.state.rating,
      headline: this.state.headline || null,
      content: this.state.content || null,
    }
    if (this.props.currUserRating.id) {
      const {id} = this.props.currUserRating
      method = 'PUT'
      rating['pk'] = id
    } else {
      method = 'POST'
    }

    
    const result = await rateItem(rating, token, method)

    this.toggle()

    if (result.id) {
      this.props.currUserRating.id ? toast.info('Commentaire mis à jour',  {position: "bottom-left"}) : toast.info('Commentaire ajouté',  {position: "bottom-left"})
      await this.props.handleRatingUpdateCallback(result)
      trackCustomEvent({
        category: "Rating",
        action: "Rating Success",
        label: `Succesfully sent this rating: ${this.state.rating}----${this.state.headline}----${this.state.content}`,
        value: result.id
      })
    }
    // const messages = []
    // if (result.hasOwnProperty('key')){
    //   messages.push(
    //     <Alert color="success">
    //       Succès ! Votre commentaire a été posté.
    //     </Alert>
    //   )
    // } else {
    //   for(let key in result){
    //     for (let msg in result[key]){
    //       messages.push(
    //       <Alert color="danger">
    //         {result[key][msg]}
    //       </Alert>
    //       )
    //     }
    //   }
    // }
    // this.setState({messages: messages})
  }

  handleStarClick(nextValue, prevValue, name){
    this.setState({rating: nextValue, starClicked: true})
  }
  handleStarHover(nextValue, prevValue, name){
    if (!this.state.starClicked){
      this.setState({rating: nextValue})
    }
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  render(){
    const button = !this.props.currUserRating.id
    ? <Button color="success" className="font-weight-bold" onClick={this.handleFormSubmit}><Trans>Envoyer</Trans></Button>
    : <Button color="success" className="font-weight-bold" onClick={this.handleFormSubmit}><Trans>Mettre à jour</Trans></Button>

    return(
      <div>
        {/* <div className="text-center justify-content-center m-auto m-sm-3 mt-sm-4"> */}
          {/* <Button className="my-3 " size="sm" onClick={this.toggle}>Écrire un commentaire</Button> */}
          <button className="btn btn-link btn-sm pl-0" onClick={this.toggle}>
            {this.props.currUserRating && this.props.currUserRating.content ? <Trans>Modifier ma note</Trans> : <Trans>Noter le film</Trans>}
          </button>
        {/* </div> */}
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}><Trans>Noter le film</Trans></ModalHeader>
            <ModalBody>
              <ModalRatingForm
                handleInputChange={this.handleInputChange} 
                handleStarClick={this.handleStarClick}
                handleStarHover={this.handleStarHover}
                headline={this.state.headline}
                content={this.state.content}
                rating={this.state.rating}
                />
            </ModalBody>
            <ModalFooter>
              {button}
            </ModalFooter>
          </Modal>
      </div>
      
    )
  }
}