import React, { useEffect, useState } from 'react'
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import {Trans, Link} from 'gatsby-plugin-react-i18next';

const fabricateGroups = props => {
    let group_names = []
    let groups = {} 

    props.episodes.sort((a,b) => (a.group_number > b.group_number) ? 1 : ((b.group_number > a.group_number) ? -1 : 0)).forEach((item,index) => {
        if (!group_names.includes(item.group_name)) {
            group_names.push(item.group_name)
            groups[item.group_name] = []
            groups[item.group_name].push(
                <Link to={`/video/${item.id}`}>
                    <DropdownItem className="btn btn-sm btn-light">{item.title === null ? `Épisode ${index + 1}` : item.title}</DropdownItem>
                    {/* Removed:  onClick={() => props.handleSelectVideo(item)} */}
                </Link>
            )
        } else {
            groups[item.group_name].push(
                <Link to={`/video/${item.id}`}>
                    <DropdownItem className="btn btn-sm btn-light">{item.title === null ? `Épisode ${index + 1}` : item.title}</DropdownItem>
                </Link>
            )
        }
    });

    let episodes = group_names.reverse().map(group_name => {
        return (
            <>  
            {group_name &&
                <DropdownItem header>{group_name}</DropdownItem>
            }
            {groups[group_name]}
            </>
        )
    })

    return episodes
}

const EpisodeSelectorBox = props => {
    const [isOpen,setIsOpen] = useState(false)
    const [episodes,setEpisodes] = useState([])
    const toggle = () => setIsOpen(!isOpen);

    // useEffect(() => {
    //     function handleEpisodes() {
    //         if(!props.episodes || props.episodes.length < 2){
    //             setEpisodes(null)
    //             return 
    //         } 
            
    

    //         setEpisodes(episodes)
    //         group_names = null
    //         groups = null
    //         episodes = null
    //     }
    //     handleEpisodes()
    //     return () => {
    //         setEpisodes(null)
    //     };
    // })

    // // if(!episodes) return <></>

    useEffect(() => {
        setEpisodes(props.episodes.map((item,index) => (
            <Link to={`/video/${item.id}`}>
                <DropdownItem active={ props.selectedVideoTitle && props.selectedVideoTitle === item.title } className="btn btn-sm btn-light">{item.title === null ? `(${index + 1}) ${props.title}` : `${item.title} (${item.group_name && item.group_name})`}</DropdownItem>
            </Link>
        )))
    },[1])

    // useEffect(() => {
    //     setEpisodes(fabricateGroups(props))
    // },[1])



    return (
        <ButtonDropdown className="my-2 mr-2" isOpen={isOpen} toggle={toggle}>
            <DropdownToggle caret size="md" color="dark">
                {/* {props.selectedVideoTitle ? props.selectedVideoTitle : props.episodes[0].title || <Trans>Sélectionner version</Trans>} */}
                <Trans>Sélectionner version</Trans>
            </DropdownToggle>
            <DropdownMenu style={{maxHeight:250, overflow:'scroll'}}>
                {episodes}
            </DropdownMenu>
        </ButtonDropdown>
    )
}

export default EpisodeSelectorBox