import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {Container, Row} from 'reactstrap'
import { Link } from 'gatsby' 
import RatingSection, {ModalRating, AllRatingsList, RatingButton} from '../RatingSection'
import StarRatingComponent from 'react-star-rating-component'
import { Trans } from 'react-i18next'

const MovieRatingBox = props => {
  let ratingNb = props.ratings.filter(i => i.headline !== null && i.content !== null ).length
  return (
    <>
    {/* <Container fluid>
      <Row>
        <div  id="video_et_description" className="mt-0 w-100">
          <div className="videoDescription px-4 py-2 text-left font-weight-light ">
            <h1>Laisser un commentaire</h1>
            <RatingButton
              objectId={props.objectId}
              type={props.type}
              ratings={props.ratings}
              currUserRating={props.currUserRating}
              user={props.user}
              token={props.token}
            />
          </div>
        </div>
      </Row>
    </Container> */}
    <Container fluid>
      <Row>
        <div  id="video_et_description" className="mt-0 w-100">
          <div className="videoDescription px-4 pb-2 pt-3 text-left font-weight-light ">
            <h1><Trans>Toutes les notes</Trans> ({ratingNb})</h1>
            <RatingButton
              objectId={props.objectId}
              handleRatingUpdateCallback={props.handleRatingUpdateCallback}
              type={props.type}
              ratings={props.ratings}
              currUserRating={props.currUserRating}
              user={props.user}
              token={props.token}
            />
            <div className="mb-3"></div>
            <AllRatingsList ratings={props.ratings} currUserRating={props.currUserRating} user={props.user} customCommentClassName="w-100"/>
          </div>
        </div>
      </Row>
    </Container>
    </>
  )
}

export default MovieRatingBox