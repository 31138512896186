import React from 'react'
import { navigate } from 'gatsby' 
import StarRatingComponent from 'react-star-rating-component'
import ModalAuth from '../ModalAuth';
import EpisodeSelectorBox from './EpisodeSelectorBox';
import {Trans, Link} from 'gatsby-plugin-react-i18next';
import { adminIds } from '../../api';

const EditPageButton = ({user, slug}) => (
  user
  ? <Link to={`/edit/${slug}`}>
      <button className="btn btn-link btn-sm pl-0">Modifier la fiche</button>
    </Link>
  : <ModalAuth>
      <button className="btn btn-link btn-sm pl-0">Modifier la fiche</button>
    </ModalAuth> 
)

const MovieTitleBox = ({title, synopsis, slug, avg_rating, ratingNumber, user, tags,moviePk,trailerPk, episodes, prefix}) => {
  
  return (
    <div className="container-fluid movie-page-container">
    <div className="row">
      <div  id="video_et_description" className="mt-0 w-100 mb-0">
  
        <div className="videoDescription px-4 text-left ">
          <h1 className="py-2 mb-0" id="video-title">{title}</h1>
          { ratingNumber ?
            <div style={{fontSize: 22}} className="d-flex align-items-center flex-wrap">
              <StarRatingComponent 
                name="rating" 
                editing={false}
                starCount={5}
                value={avg_rating}
              />
              <span style={{fontSize: 15, marginBottom: '0.5em', marginLeft: 5}}>({ratingNumber})</span>
            </div>
            : ''
          }
        </div>


        <div className="videoDescription px-4 pb-2 text-left font-weight-light ">
          <h6><Trans>Synopsis</Trans></h6>
          <p className="text-left mb-1"> 
            {synopsis}
          </p>
          {/* {tags && tags.length ? <p> { tags.map(i => <span> <Link className="" to={`/list/${i[1]}`}> {i[0]}</Link>, </span>) } </p>: ''}  */}
          {tags && tags.length ? <p> { tags.map(i => <span onClick={() => {
              let query = i[0]
              navigate(`${prefix}search?query=${query}`, {
                state: { query },
              })
          }} > <span className="link cursor-pointer text-primary" >{i[0]}</span>, </span>) } </p>: ''} 
          <span className="position-absolte bottm-0">
            {/* { moviePk &&
              <Link to={`/video/${moviePk}`} ><button className="btn btn-dark btn-lg my-2 mr-2">&#9658; <Trans>Lecture</Trans> </button></Link> 
            }
            { trailerPk &&
              <Link to={`/video/${trailerPk}`} ><button className="btn btn-secondary btn-sm my-2 mr-2">&#9658; <Trans>Bande annonce</Trans> </button></Link>
            } */}
            { episodes &&
              <EpisodeSelectorBox title={title} className="mr-2" episodes={episodes} />
            }
            {/* <a className="btn btn-secondary btn-sm my-2 mr-2" rel="nofollow" target="_blank" href={`https://www.amazon.fr/gp/search?ie=UTF8&tag=cinetimes0f-21&linkCode=ur2&linkId=40c1ae6e0d692e9baa16dc02712f52c9&camp=1642&creative=6746&index=dvd&keywords=${title}`}><Trans>Acheter</Trans> &rArr; </a> */}
            {user && adminIds.includes(parseInt(user.pk)) === 1162 && <span><br/><EditPageButton user={user} slug={slug}/></span>}

          </span>
        </div>
      </div>

    </div>
  </div>
  )
}

export default MovieTitleBox