import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby' 
import Rows from '../Rows'
import {css} from '@emotion/react'
import Row, { RowSM, RowMobile, RowMobileSM } from '../Row'
import { is_touch_device, slugify } from '../../utils'
import { fetchRelated } from '../../api'
import { Spinner } from 'reactstrap'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

class MovieRelatedBox extends React.Component {
    static propTypes = {
        translationId: PropTypes.number,
    }

    state = {
        related: null,
        loading: true,
    }


    // async componentDidMount() {
    //     const {translationId} = this.props
    //     const related = await fetchRelated(translationId)
    //     if (related) {
    //         this.setState({
    //             related: related.results,
    //             loading: false
    //         })
    //     } else {
    //         this.setState({
    //             loading: false
    //         })
    //     }
    // }
    
    render(){
        let title = this.props.title ? this.props.title : "Contenu associé"
        let message = '' 
        if (!this.props.translations ||!this.props.translations.length) return ''
        if (!this.props.translations ||!this.props.translations.length) message = "Pas de recommendations pour le moment"


        let isMobile = false
        if (typeof window !== `undefined`) {
          isMobile = window.innerWidth <= 767 || is_touch_device()
        }
        const row = isMobile ? <RowMobileSM size="sm" className="m-0 p-0" movies={this.props.translations} disabledMoreButton/> : <RowSM size="sm" className="m-0 p-0" movies={this.props.translations} disabledMoreButton/> 
        return (
            <div id={slugify(title)} className="container-fluid movie-page-container">
                <div className="row">
                    <div  id="video_et_description" className="mt-0 w-100">    
                        <div className="videoDescription p-4 pb-2 text-left font-weight-light">
                            <h1>{title}</h1>
                            {/* { this.state.loading &&
                                <div className="m-auto text-center" style={{maxWidth: 1200}}>
                                    <Spinner className="m-auto text-center" size="sm"/>
                                </div>
                            } */}
                            <span onClick={() => {
                                trackCustomEvent({
                                    category: "Related movie click",
                                    action: "Click",
                                    label: `related click on ${title}`,
                                }) 
                            }}>

                                {row}
                            </span>
                            <span>{message}</span>
                        </div>
                    </div>
            
                </div>
            </div>
        )
    }
}

const item = css({
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0,0,0,0.2)',
    display: 'inline-block',
    marginRight: 10,
    position: 'relative',
    boxShadow: '0 1px 6px rgba(0,0,0,0.2)',
    fontSize: '0',
    // height: 98,
    // width: 67,
  })
  

export default MovieRelatedBox