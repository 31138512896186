import React from 'react'
import StarRatingComponent from 'react-star-rating-component'
import {Button} from 'reactstrap'

export default props => (
  <div 
    className={!props.customCommentClassName ? "my-1 bg-white w-100 col-12 col-sm-9 rounded border border-white pt-3 px-3" 
  : props.customCommentClassName}>
  <span className="font-weight-bold"> {props.title} </span>
  <br />
    <StarRatingComponent 
      name="rating" 
      editing={false}
      starCount={5}
      value={props.rating}
      className="position-absolute"
    />

    <br/>
    <p className="font-weight-light">{props.content} </p>
    <p className="font-weight-lighter text-small ">{props.author}, 18 janvier 2019 </p>
  </div>
)

export const CommentImproved =  props => (
  <div 
    className={!props.customCommentClassName ? "my-1 bg-white w-100 col-12 col-sm-9 rounded border border-white pt-3 px-3" 
  : props.customCommentClassName}>
  <span className="font-weight-bold"> {props.title} </span>
  <br />
    <StarRatingComponent 
      name="rating" 
      editing={false}
      starCount={5}
      value={props.rating}
      className="position-absolute"
    /> <p className="font-weight-lighter text-small mb-1" style={{marginLeft: 90}}>({props.author} - {new Date(props.date).toLocaleDateString('fr-FR', {year: 'numeric', day: 'numeric', month: 'short'})})</p>

    <p className="font-weight-light mb-4">{props.content} </p>
  </div>
)
