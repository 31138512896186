import React, { useState, useEffect } from 'react';
import { addLike, deleteLike } from '../apis/like';
import { getToken } from '../api';
import { navigate } from 'gatsby';

export default function ButtonsLike(props) {
    const [like, setLike] = useState(false)
    const [dislike, setDisliked] = useState(false)
    
    const {movie} = props
    let token = typeof localStorage.getItem('userToken') === "string"

    useEffect(() => {
        setLike(movie.liked)
        setDisliked(movie.disliked)
    },[])

    const onLike = () => {
        if (!token){
            navigate("/user/login/")
            return
        }
        if (like === true) deleteLike(movie.id)
        else addLike(movie.id, true)
        setLike(!like)
        setDisliked(false)
    };
    const onDislike = () => {
        if (!token){
            navigate("/user/login/")
            return
        }
        if (dislike === true) deleteLike(movie.id)
        else addLike(movie.id, false)
        setDisliked(!dislike)
        setLike(false)
    };

  
    return (
        <div>
            {token}
            <button
                onClick={onLike}
                className={`btn ${like ? 'btn-dark' : 'btn-secondary'} my-2`}>👍
            </button>
            <button
                onClick={onDislike}
                className={`btn ${dislike ? 'btn-dark' : 'btn-secondary'} mx-2 my-2`}>👎
            </button>
        </div>
    );
}