import React from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/react'

import disneyplus from '../../images/logo-disney-plus.png'
import cinetimes from '../../images/logo-cinetimes.png'
import netflix from '../../images/logo-netflix.png'
import amazon from '../../images/logo-prime-video.png'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Link } from 'gatsby'

// "watchOn": [
//     {
//         "id": 35,
//         "price": null,
//         "movie_translation": 8795,
//         "streaming_service": 1
//     }
// ]

class HowToSeeBox extends React.Component {
    static propTypes = {
        watchOn: PropTypes.array, 
    }

    state = {}


    async componentDidMount() {
        // const {translationId} = this.props
        // const related = await fetchRelated(translationId)
        // if (related) {
        //     this.setState({
        //         related: related.results,
        //     })
        // }
    }
    
    render(){
        const {watchOn, movies} = this.props
        // if(!watchOn.length && !movies.length) return '' hide even when on cinetimes (Found a better way to increase session time)
        if(!watchOn.length) return ''

        const services = []

        if (watchOn && watchOn.length){
            watchOn.forEach(i => {
                if (i.streaming_service === 1) {
                    services.push(
                        <div className="card" style={{width: '8.5rem'}}>
                            <OutboundLink href="/out/disneyplus" target="_blank" className="text-dark text-decoration-none">
                                <img loading="lazy" style={{height: '5.6rem'}} src={disneyplus} className="card-img-top" alt="logo disney plus"/>
                                <div className="card-body py-1 text-center">
                                    <p className="card-text">Disney +</p>
                                </div>
                            </OutboundLink>
                        </div>
                    )
                } else if(i.streaming_service === 7){
                    services.push(
                        <div className="card" style={{width: '8.5rem'}}>
                        <OutboundLink href="/out/netflix" target="_blank" className="text-dark text-decoration-none">
                            <img loading="lazy" style={{height: '5.6rem'}} src={netflix} className="card-img-top" alt="logo netflix"/>
                            <div className="card-body py-1 text-center">
                                <p className="card-text">Netflix</p>
                            </div>
                        </OutboundLink>
                        </div>
                    )
                } else if(i.streaming_service === 4){
                    services.push(
                        <div className="card" style={{width: '8.5rem'}}>
                            <div style={{maxHeight: '5.6rem', overflow:'hidden'}} >
                                <img loading="lazy" src={cinetimes} className="card-img-top" alt="logo cinetimes"/>
                            </div>
                            <div className="card-body py-1 text-center">
                                <p className="card-text">Cinetimes</p>
                            </div>
                        </div>
                    )
                } else if(i.streaming_service === 8){
                    services.push(
                        <div className="card" style={{width: '8.5rem'}}>
                            <OutboundLink href="/out/primevideo" target="_blank" className="text-dark text-decoration-none" >
                                <img loading="lazy" style={{height: '5.6rem', overflow:'hidden'}} src={amazon} className="card-img-top" alt="logo amazon prime video"/>
                                <div className="card-body py-1 text-center">
                                    <p className="card-text">Prime Video</p>
                                </div>
                            </OutboundLink>
                        </div>
                    )
                }

                
            })
        }
        if (movies && movies.length){
            services.push(
                <div className="card" style={{width: '8.5rem'}}>
                    <Link to={`/video/${movies[0].id}`} state={{ videoId: movies[0].id }} className="underline-not" title="">
                        <img loading="lazy" style={{height: '5.6rem', overflow:'hidden'}} src={cinetimes} className="card-img-top" alt="logo cinetimes"/>
                        <div className="card-body py-1 text-center">
                            <p className="card-text">Cinetimes</p>
                        </div>
                    </Link>
                </div>
            )
        }

        return (
            <div className="container-fluid movie-page-container">
                <div className="row">
                    <div  id="video_et_description" className="mt-0 w-100">    
                        <div className="videoDescription p-4 pb-2 text-left font-weight-light ">
                            <h1>Comment regarder ?</h1>
                            <div className="y-scroll">
                                {services}
                            </div>
                        </div>
                    </div>
            
                </div>
            </div>
        )
    }
}

const item = css({
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0,0,0,0.2)',
    display: 'inline-block',
    marginRight: 10,
    position: 'relative',
    boxShadow: '0 1px 6px rgba(0,0,0,0.2)',
    fontSize: '0',
    // height: 98,
    // width: 67,
  })
  

export default HowToSeeBox