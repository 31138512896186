import React from 'react'
import { Popover } from 'react-tiny-popover'
import StarRatingComponent from 'react-star-rating-component'

export default class StarRatingPopover extends React.Component {
  state = {
    isPopoverOpen: false,
  }

  togglePopover = (bool) => {
    this.setState({ isPopoverOpen: bool })
    if (!this.props.token) return

    if (bool === false) {
      this.props.handleFormSubmit()
    }
  }

  render(){
    const isPopoverOpen = this.state.isPopoverOpen
    let content
    if (!this.props.token){
      content = (
        <div className="pb-1">
          <span>Connectez-vous pour pouvoir noter ce film</span>
          
          <button 
            onClick={() => this.togglePopover(false)}
            className="btn btn-sm btn-link ml-2 mb-1">
              OK
          </button>
        </div>
      )
    } else {
      content = (
        <div className="d-flex align-items-center w-100">
          <StarRatingComponent 
            name="rating" 
            editing={true}
            starCount={5}
            value={this.props.rating}
            onStarClick={this.props.handleStarClick}
            onStarHover={this.props.handleStarHover}
          />
          <span className="ml-2 mb-2">{this.props.rating}/5</span>
          <button 
            onClick={() => this.togglePopover(false)}
            className="btn btn-sm btn-link ml-2 mb-2"
            >OK</button>
        </div>
      )
    }
    return (
      <Popover
        isOpen={isPopoverOpen}
        position={['bottom', 'top', 'left', 'right']} // preferred position
        containerClassName="bg-white border rounded px-3 pt-2"
        content={content}
      >
        <div onClick={() => this.setState({ isPopoverOpen: true })} className="d-flex align-items-center">
            {this.props.children}
        </div>
      </Popover>
    )
  }
}