import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import { removeFromWatchlistLocalStorage, addToWatchlistLocalStorage } from '../../utils/localStorage'
import { deleteWatchlist, addWatchlist } from '../../apis/watchlist'
import { getToken } from '../../api'
import { Trans } from 'react-i18next'


class WatchlistButton extends React.Component {

    static propTypes = {
        watchlist: PropTypes.array.isRequired,
        movieTranslation: PropTypes.any.isRequired,
        inWatchlist: PropTypes.bool,
    }

    state = {
        isMovieInWatchlist: false,
        isLoading: false,
    }

    componentDidMount(){
        const {watchlist, movieTranslation, inWatchlist} = this.props
        
        if (!watchlist || !movieTranslation) return
        
        const isItemIn = watchlist.filter(item => item.id === movieTranslation.id)

        if (isItemIn && isItemIn.length || inWatchlist){
            this.setState({ isMovieInWatchlist: true })
        }

    }

    addToWatchlist = async () => {
        this.setState({isLoading: true})
        const userToken = await getToken()

        let { watchlist, movieTranslation } = this.props
        const { isMovieInWatchlist } = this.state
      
        if(isMovieInWatchlist) {
            if (userToken) await deleteWatchlist(movieTranslation.id)
            watchlist = await removeFromWatchlistLocalStorage(movieTranslation)
            toast.info(`✅`)
            toast.info(`Supprimé de ma liste`)
            try {
                window.OneSignal.sendTags({
                    "watchlist_update": "",
                    "watchlist_push_title": "",
                    "watchlist_push_slug": "",
                    "watchlist_push_image_url": "",
                })
            } catch(err){console.log(err)}
        } else {
            if (userToken) await addWatchlist(movieTranslation.id)
            watchlist = await addToWatchlistLocalStorage(movieTranslation)
            toast.info(`✅`)
            try {
                let timestamp = Math.floor(Date.now() / 1000);
                window.OneSignal.sendTags({
                    "watchlist_update": timestamp,
                    "watchlist_push_title": movieTranslation.title,
                    "watchlist_push_slug": movieTranslation.slug,
                    "watchlist_push_image_url": movieTranslation.poster,
                })
            } catch(err){console.log(err)}
            // toast.info(`Ajouté à ma liste`)
        }

        this.setState({isMovieInWatchlist: !isMovieInWatchlist, watchlist: watchlist, isLoading: false})
    }

    
    render() {
        const {buttonStyle} = this.props
        const {isMovieInWatchlist, isLoading} = this.state
        return (
            <span>
                { !isMovieInWatchlist && !isLoading && <button className={`btn ${buttonStyle === "small" && 'btn-sm btn-light' || 'btn-outline-dark'} my-2`} onClick={this.addToWatchlist} >+&nbsp; <Trans>Ma liste</Trans> </button>}
                {  isMovieInWatchlist && !isLoading && <button className={`btn ${buttonStyle === "small" && 'btn-sm btn-dark' || 'btn-dark'} my-2`}  onClick={this.addToWatchlist} >
                    <span className="font-weight-bold">− </span> <Trans>Ma liste</Trans>
                </button> }

                { !isMovieInWatchlist && isLoading && <button className={`btn ${buttonStyle === "small" && 'btn-sm btn-light' || 'btn-outline-dark'} my-2`} ><Spinner size="sm"/></button> }
                {  isMovieInWatchlist && isLoading && <button className={`btn ${buttonStyle === "small" && 'btn-sm btn-dark' || 'btn-dark'} my-2`} ><Spinner size="sm"/>  </button> }
            </span>
        )
    }
}

export default WatchlistButton

