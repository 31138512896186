import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {Container, Row} from 'reactstrap'
import { Link } from 'gatsby' 
import WatchlistButton from '../buttons/WatchlistButton'
import { addLike } from '../../apis/like'
import ButtonsLike from '../ButtonsLike'
import EpisodeSelectorBox from './EpisodeSelectorBox'

const MovieButtonsBox = props => {
  const buttons = []
  props.firstMovie && buttons.push(
    <Link to={`/video/${props.firstMovie.id}`} state={{ videoId: props.firstMovie.id }} key={props.firstMovie.id} className="">
      <button className="btn btn-dark my-2 mr-2"> &#9658; Voir ici</button>
    </Link>
  )
  // buttons.push(
  //   <div key={props.title}>
  //     <a className="btn btn-link btn-sm pl-0" target="_blank" href={`https://www.amazon.fr/gp/search?ie=UTF8&tag=ctimes-21&linkCode=ur2&linkId=6754ff1a323f2b5c5783c1a5be8bc510&camp=1642&creative=6746&index=dvd&keywords=${props.title}`}>Acheter</a>
  //   </div>
  // )
  // buttons.push(
  //   <span key="rate">
  //     {props.rate}
  //   </span>
  // )
  // buttons.push(
  //   <Link to="" className="">
  //     <button className="btn btn-link btn-sm pl-0">Voir en VOD</button>
  //   </Link>
  // )
  // buttons.push(
  //   <Link to="" className="">
  //     <button className="btn btn-link btn-sm pl-0">Ajouter à la liste d'envies</button>
  //   </Link>
  // )

  const {movie, movieTranslation, watchlist} = props

  return (
    <Container fluid>
      <Row>
        <div  id="video_et_description" className="mt-0 w-100">
          <div className="videoDescription px-4 py-2 text-left font-weight-light d-flex justify-content-center text-center flex-wrap">
            {buttons}
            { props.episodes && props.episodes.length 
              ? <div> <EpisodeSelectorBox title={props.title} className="mr-2" episodes={props.episodes} /> </div>
              : <></>
            }
            <ButtonsLike movie={movie}/>
            <WatchlistButton inWatchlist={props.inWatchlist} movieTranslation={movieTranslation} watchlist={watchlist}/>
          </div>
        </div>
      </Row>
    </Container>
  )
}

export default MovieButtonsBox

