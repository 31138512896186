import React from 'react'
import PropTypes from 'prop-types'
import { AmazonProductRowMobileSM } from '../Row'
import { is_touch_device } from '../../utils'


class AffiliateProductRelatedBox extends React.Component {
    static propTypes = {
        products: PropTypes.array,
    }

    state = {
        related: null,
        loading: true,
    }

    
    render(){
        let message = '' 
        if (!this.props.products || !this.props.products.length) return ''
        if (!this.props.products || !this.props.products.length) message = "Produits désactivés sur cette page."


        let isMobile = false
        if (typeof window !== `undefined`) {
          isMobile = window.innerWidth <= 767 || is_touch_device()
        }
        const row = <AmazonProductRowMobileSM size="sm" className="m-0 p-0" slug={`/t/${this.props.slug}`} products={this.props.products} translationId={this.props.translationId} disabledMoreButton/>
        return (
            <div className="container-fluid movie-page-container">
                <div className="row">
                    <div  id="video_et_description" className="mt-0 w-100">    
                        <div className="videoDescription p-4 pb-2 text-left font-weight-light ">
                            <h1>Produits sponsorisés</h1>
                            {row}
                            <span>{message}</span>
                        </div>
                    </div>
            
                </div>
            </div>
        )
    }
}

export default AffiliateProductRelatedBox