import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Trans, Link } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const MovieDetailsBox = props => {
  const title = !props.title ? '' 
  : (    
    <p>
      <span className="font-weight-bold"><Trans>Titre</Trans>: </span><br/>
      <span className="">{props.title}</span>
    </p>
    )
  const original_title = !props.original_title ? '' 
  : (
    <p>
      <span className="font-weight-bold"><Trans>Titre Original</Trans>: </span><br/>
      <span className="">{props.original_title}</span>
    </p>
  )
  const release = !props.release ? '' 
  : (
    <p>
      <span className="font-weight-bold"><Trans>Date de sortie</Trans>: </span><br/>
      <span className="">{new Date(props.release).toLocaleDateString('fr-FR', {year: 'numeric', month: 'long', day: 'numeric' })}</span> 
    </p>
  )
  const original_country = !props.original_country ? '' 
  : (
    <p>
      <span className="font-weight-bold"><Trans>Pays d'origine</Trans>: </span><br/>
      <span className="">{props.original_country}</span> 
    </p>
  )
  const runtime = !props.runtime ? '' 
  : (
    <p>
      <span className="font-weight-bold"><Trans>Durée</Trans> </span><br/>
      { props.runtime > 60 &&
        <span className="">{Math.floor(props.runtime/60)}h {props.runtime % 60}min</span> 
      }
      { props.runtime === 60 &&
        <span className="">1h</span> 
      }
      { props.runtime < 60 &&
        <span className="">{props.runtime}min</span> 
      }
    </p>
  )
  const budget = !props.budget ? '' 
  : (
    <p>
      <span className="font-weight-bold"><Trans>Budget</Trans>: </span><br/>
      <span className="">
        {formatter.format(props.budget).replace(/\D00$/, '')}
      </span>
    </p>
  )
  const box_office = !props.box_office ? '' 
  : (
    <p>
      <span className="font-weight-bold"><Trans>Box Office</Trans>: </span><br/>
      <span className="">{formatter.format(props.box_office).replace(/\D00$/, '')}
      </span>
    </p>
  )
  const genreNames = !props.genreNames || !props.genreNames.length ? '' 
  : (
    <p>
      <span className="font-weight-bold"><Trans>Genres</Trans>: </span><br/>
      <span className="">{props.genreNames}</span>
    </p>
  )
  const tags = !props.tags || !props.tags.length ? '' 
  : (
    <p>
      <span className="font-weight-bold"><Trans>Tags</Trans>: </span><br/>
      {/* { props.prefix === "/" &&
        props.tags.map(i => <span> <Link className="" to={`/list/${i[1]}`}> {i[0]}</Link>, </span>)
      } */}
      {props.tags && props.tags.length ? <p> { props.tags.map(i => <span onClick={() => {
        let query = i[0]
        navigate(`${props.prefix}search?query=${query}`, {
          state: { query },
        })
      }} > <span className="link cursor-pointer text-primary" >{i[0]}</span>, </span>) } </p>: ''} 
    </p>
  )
  return (
    <div className="text-left bg-white p-3">
      <h5><Trans>Détails</Trans></h5>
      {title}
      
      {original_title}
      
      {release}
      
      {original_country}
      
      {runtime}
      
      {budget}
      
      {box_office}
      
      {/* {genreNames} */}

      {tags}
    </div>
  )
}

export default MovieDetailsBox